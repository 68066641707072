<template>
  <div class="to-do-list">
    <van-dropdown-menu active-color="#B9921A">
      <van-dropdown-item
        v-model="taskCode"
        :options="taskData.options"
        @change="onRefresh"
      />
      <van-dropdown-item
        ref="dropdownTime"
        :title="
          `${
            $moment().isSame(start, 'day')
              ? $t('今天')
              : $moment(start).format('YYYY-MM-DD')
          }`
        "
        disabled
      >
        <van-calendar
          style="height: 394px"
          :poppable="false"
          :show-title="false"
          type="range"
          :first-day-of-week="1"
          allow-same-day
          :show-confirm="false"
          :default-date="[new Date($moment(start)), new Date($moment(end))]"
          @confirm="onConfirm"
        ></van-calendar>
      </van-dropdown-item>
    </van-dropdown-menu>
    <p class="total">{{ $t("共") }} {{ totalCount }} {{ $t("条数据") }}</p>
    <List v-if="$store.state.user.userInfo.id" ref="list" :fetchApi="fetchApi">
      <template v-slot:default="{ item }">
        <div v-if="isForLead(item)" class="item-wrap" @click="getPath(item)">
          <div class="user-info">
            <ShortConsumerInfo :data="item.lead">
              <span style="margin-left: auto;color: #FFD100;font-size: 15px;">
                {{ taskData.nameConfig[item.taskCode] }}
              </span>
            </ShortConsumerInfo>
          </div>
          <CardOptions :data="item.lead" />
          <div class="mb-8">
            <span>{{ $t("用户来源") }}：</span>
            <span>{{ item.lead | sourceText }}</span>
          </div>
          <div class="van-ellipsis mb-8">
            <span>{{ $t("跟进人") }}：</span>
            <span>{{ item.lead.followUserName || "--" }}</span>
            <template v-if="item.lead.followRemark">
              <span> | </span>
              <span>{{ item.lead.followRemark }}</span>
            </template>
          </div>
          <!-- 任务倒计时 -->
          <div v-if="item.planTime && $moment().diff(item.planTime, 'hours') <= 0 && $moment().diff(item.planTime, 'hours') > -24" class="van-ellipsis mb-8">
            <span class="yuqi">{{$t('任务倒计时')}}：</span>
            <span class="yuqi">
                {{ getTimeLine(item.planTime) }}
              </span>
          </div>
          <div class="actions">
            <span
              v-if="$moment().diff(item.planTime, 'hours') > 0"
              class="yuqi"
            >
             {{ getYuQiTime(item.planTime) }}
            </span>
            <span
              v-btn-premission="['BTN-CLUE-DISTRIBUTE']"
              class="assigned"
              @click.stop="
                () => {
                  distributionRole = item.lead.followUserRoleCode;
                  showDistribution = true;
                  distributionIds = [item.leadId];
                }
              "
            >
              {{ $t("重新分配") }}
            </span>
            <QwIcon
              v-if="item.lead.userWeChat"
              class="ml-16"
              :externalUserIds="item.lead.userWeChat"
            />
            <PhoneIcon
              :phone="item.lead.userMobile"
              :oneself="item.lead.followUserId === $store.state.user.userInfo.id"
              :clueId="item.lead.id"
              class="ml-16"/>
            <img
              v-if="$store.state.user.userInfo.type === 1"
              width="32px"
              class="ml-16"
              :src="require('@/images/follow@2x.png')"
              @click.stop="getPath(item, true)"
            />
          </div>
        </div>
        <template v-else-if="isForOrder(item)">
          <OrderItem
            v-if="
              item?.lead?.orders?.length && item.lead.orders[0].orderType !== 1
            "
            :dataSource="item.order"
            class="order"
          >
            <span slot="btns" class="todo-actions">
              <span
                v-if="$moment().diff(item.planTime, 'hours') > 0"
                class="yuqi"
              >
              {{ getYuQiTime(item.planTime) }}
              </span>
              <span
                v-btn-premission="['BTN-CLUE-DISTRIBUTE']"
                class="assigned"
                @click.stop="
                  () => {
                    distributionRole = item.lead.followUserRoleCode;
                    showDistribution = true;
                    distributionIds = [item.leadId];
                  }
                "
              >
                {{ $t("重新分配") }}
              </span>
              <PhoneIcon
                :phone="item.lead.userMobile"
                :oneself="item.lead.followUserId === $store.state.user.userInfo.id"
                :clueId="item.lead.id"
                class="ml-16"/>
              <img
                v-if="$store.state.user.userInfo.type === 1"
                width="32px"
                class="ml-16"
                :src="require('@/images/follow@2x.png')"
                @click.stop="getPath(item, true)"
              />
            </span>
          </OrderItem>
          <!-- 大客户 -->
          <BigOrderItem
            v-if="
              item?.lead?.orders?.length && item.lead.orders[0].orderType === 1
            "
            :orderDetails="item.lead.orders[0]"
            :relationId="item.relationId"
            :yuqi="getYuQiTime(item.planTime)"
          />
        </template>
        <PdcCard
          v-else-if="isForStorePdc(item)"
          :data="item.appointment"
          :is-todo="true"
          class="card">
          <span slot="btns" class="todo-actions">
            <span
              v-btn-premission="['BTN-CLUE-DISTRIBUTE']"
              style="vertical-align: text-bottom;"
              class="assigned"
              @click.stop="
                () => {
                  distributionRole = item.lead.followUserRoleCode;
                  showDistribution = true;
                  distributionIds = [item.leadId];
                }
              "
            >
              {{ $t("重新分配") }}
            </span>
            <PhoneIcon
              :phone="item.lead.userMobile"
              :oneself="item.lead.followUserId === $store.state.user.userInfo.id"
              :clueId="item.lead.id"
              class="ml-16"/>
            <img
              v-if="$store.state.user.userInfo.type === 1"
              width="32px"
              class="ml-16"
              :src="require('@/images/follow@2x.png')"
              @click.stop="getPath(item, true)"
            />
          </span>
        </PdcCard>
        <Card
          v-else-if="isForAppointment(item)"
          :data="item.appointment"
          :is-todo="true"
          class="card"
          :yuqi="
            $moment().diff(item.planTime, 'hours') > 0
              ? getYuQiTime(item.planTime)
              : ''
          ">
          <span slot="btns" class="todo-actions">
            <span
              v-btn-premission="['BTN-CLUE-DISTRIBUTE']"
              style="vertical-align: text-bottom;"
              class="assigned"
              @click.stop="
                () => {
                  distributionRole = item.lead.followUserRoleCode;
                  showDistribution = true;
                  distributionIds = [item.leadId];
                }
              "
            >
              {{ $t("重新分配") }}
            </span>
            <PhoneIcon
              :phone="item.lead.userMobile"
              :oneself="item.lead.followUserId === $store.state.user.userInfo.id"
              :clueId="item.lead.id"
              class="ml-16"/>
            <img
              v-if="$store.state.user.userInfo.type === 1"
              width="32px"
              class="ml-16"
              :src="require('@/images/follow@2x.png')"
              @click.stop="getPath(item, true)"
            />
          </span>
        </Card>
      </template>
    </List>
    <!-- 员工列表面板 -->
    <BusinessFilters
      ref="filter"
      v-model="showDistribution"
      :options="[
        {
          name: '',
          type: 'B_PEOPLE_SELECTION',
          field: 'saIdList',
          isSelection: true,
          valueType: 'object',
          shopIds: '',
          height: '80vh',
          roleCodes: distributionRole,
        },
      ]"
      :defaultActiveKey="['saIdList']"
      :buttonNameReset="$t('取消')"
      @reset="showDistribution = false"
      @submit="distributionFilter"
    >
    </BusinessFilters>
  </div>
</template>
<script>
import List from '@/components/baseList/list.vue'
import ShortConsumerInfo from '@/components/card-view/short-consumer-info.vue'
import QwIcon from '@/components/card-view/qw-icon.vue'
import PhoneIcon from '@/components/card-view/phone-icon.vue'
import loading from '@/utils/loading'
import todoServices from '@/services/todoServices.js'
import CardOptions from '@/components/card-view/cardOptions.vue'
import { copyPhone, dateFormat } from '@/utils'
import OrderItem from '@/modules/order/component/orderItem.vue'
import Card from '@/modules/appointment/component/card.vue'
import PdcCard from '@/modules/appointmentShop/component/card.vue'
import clueServices from '@/services/clueServices'
import deliveryServices from '@/services/deliveryServices'
import BigOrderItem from '@/modules/order/component/bigOrderItem.vue'
import { mapGetters } from 'vuex'
let vm = this
export default {
  name: 'MyTodoTodoList',
  filters: {
    sourceText(data) {
      let arr = []
      const { channelOne, channelTwo, channelThree } = data
      channelOne && arr.push(channelOne)
      channelTwo && arr.push(channelTwo)
      channelThree && arr.push(channelThree)
      return arr.length > 0 ? arr.join('/') : vm.$t('暂无')
    },
  },
  components: {
    List,
    ShortConsumerInfo,
    QwIcon,
    PhoneIcon,
    CardOptions,
    OrderItem,
    Card,
    PdcCard,
    BigOrderItem,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('app/addKeepAlive', 'MyTodoTodoList')
      if (
        [
          '/order-follow',
          '/order-detail',
          '/clue-follow',
          '/clue-details',
        ].includes(from.path)
      ) {
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    if (
      ![
        '/order-follow',
        '/order-detail',
        '/clue-follow',
        '/clue-details',
      ].includes(to.path)
    ) {
      this.$store.commit('app/deleteKeepAlive', 'MyTodoTodoList')
    }
    next()
  },
  data() {
    return {
      distributionIds: [],
      distributionRole: '',
      showDistribution: false,
      totalCount: 0,
      taskCode: this.$route.query.taskCode,
      start: this.$route.query.start,
      end: this.$route.query.end,
    }
  },
  
  computed: {
    ...mapGetters(['dictHash']),
    authDistribution() {
      let authRoleCodes = ['1014003', '1014004'] // 店长、MASTER
      const roleHash = this.$store.getters.getDictHash(1014) || []
      return roleHash.some(({ code }) => authRoleCodes.includes(code))
    },
    taskData() {
      let nameConfig = {}
      let codeConfig = {}
      const options = []
      this.$store.getters.getDictHash(5001)?.map((item) => {
        nameConfig[item.code] = item.name
        if (item.descr) {
          codeConfig[item.code] = item.descr
          options.push({
            ...item,
            text: item.name,
            value: item.code,
          })
        }
        return
      })
      return {
        options,
        nameConfig,
        codeConfig,
      }
    },
  },
  watch: {
    dictHash: {
      handler(val) {
        this.onRefresh()
      },
      immediate: true
    }
  },
  activated() {
    this.onRefresh()
  },
  methods: {
    async distributionFilter({ saIdList = [] }) {
      if (!saIdList || !saIdList.length) {
        this.$toast(this.$t('请选择顾问'))
        return
      }
      const [{ id, name }] = saIdList
      // if (this.isForOrder({ taskCode: this.taskCode })) {
      //   this.assign({ id, name })
      //   return
      // }
      const params = {
        empId: id,
        empName: name,
        ids: this.distributionIds,
        roleLogo: this.distributionRole,
      }
      await clueServices.clueDistribution(params)
      this.onRefresh()
    },
    // 订单分配
    async assign({ id, name }) {
      // 分配参数
      const distributeParams = {
        deliveryUserId: id,
        deliveryUserName: name,
        orderIds: this.distributionIds,
        type: 2,
      }
      loading.showLoading()
      await deliveryServices.deliverAllocationPost(distributeParams)
      this.onRefresh()
    },
    isForLead(item) {
      return [
        'LEAD_FIRST_FOLLOW',
        'LEAD_FOLLOW',
        'DEFEAT_FOLLOW',
        'LEAD_FOLLOW_FUTURE',
        'LEAD_OVERDUE',
        'DEFEAT_FOLLOW', // 战败回访
        'INVALID_FOLLOW', // 无效回访
      ].includes(item.taskCode)
    },
    isForOrder(item) {
      return [
        'ORDER_LOCK',
        'ORDER_CONTRACT_PUSH',
        'ORDER_CONTRACT_SIGN',
        'DELIVERY_VISIT',
        'ORDER_ASSIGN'
      ].includes(item.taskCode)
    },
    isForAppointment(item) {
      return [
        'APPOINT_TO_CONFIRM',
        'APPOINT_PDC',
        'APPOINT_FOLLOW',
        'AFTER_DRIVE_FOLLOW',
      ].includes(item.taskCode)
    },
    isForStorePdc(item) {
      return [
        'APPOINT_STORE_PDC'
      ].includes(item.taskCode)
    },
    onCallPhone(item) {
      if (this.$isMobile) {
        location.href = `tel:${item.lead.userMobile}`
        this.getPath(item, true, 1010001)
      } else {
        copyPhone(
          item.lead.userMobile
          // this.$t('隐私号码复制成功')
        )
      }
    },
    getYuQiTime(planTime) {
      if (this.$moment().diff(planTime, 'hours') > 0) {
        const diff = this.$moment().diff(planTime, 'seconds')
        // 将秒数转换为天和小时
        const days = Math.floor(diff / (24 * 3600))
        const hours = Math.floor((diff % (24 * 3600)) / 3600)
        // if (days && hours) {
        //   return `${this.$t('逾期时长')}: ${days}${this.$t('天'  )}${hours}${this.$t('小时')}`
        // } else if (days) {
        //   return `${this.$t('逾期时长')}: ${days}${this.$t('天')}`
        // } else if (hours) {
        //   return `${this.$t('逾期时长')}: ${hours}${this.$t('小时')}`
        // }

        const minutes = Math.ceil((diff % (24 * 3600)) % 3600 / 60) // 向上取整
        const str1 = days ? `${days} day ` : ''
        const str2 = hours ? `${hours} h ` : ''
        const str3 = minutes ? `${minutes} min` : ''
        const label = `${this.$t('逾期时长')}: `
        return label.concat(str1).concat(str2).concat(str3)
      } else {
        return ''
      }
    },
    getTimeLine(planTime) {
      let flag = this.$moment().diff(planTime, 'hours') <= 0 && this.$moment().diff(planTime, 'hours') > -24
      
      if (flag) {
        let diff = this.$moment().diff(planTime, 'seconds')
        diff = Math.abs(diff)
        // 将秒数转换为天和小时
        const days = Math.floor(diff / (24 * 3600))
        const hours = Math.floor((diff % (24 * 3600)) / 3600)
        const minutes = Math.ceil(((diff % (24 * 3600)) % 3600) / 60) // 向上取整
        const str1 = days ? `${days} day ` : ''
        const str2 = hours ? `${hours} h ` : ''
        const str3 = minutes ? `${minutes} min` : ''
        return str1.concat(str2).concat(str3)
      } else {
        return ''
      }
    },

    onConfirm(data) {
      this.$refs.dropdownTime.toggle(false)
      this.start = this.$moment(data[0]).format('YYYY-MM-DD')
      this.end = this.$moment(data[1]).format('YYYY-MM-DD')
      this.onRefresh()
    },
    onRefresh() {
      this.$refs?.list?.onRefresh()
    },
    // 获取列表数据
    fetchApi(obj = {}) {
      const { dealers, type } = this.$store.state.user.userInfo
      const { roleCode='', parentCode='', childCode, dealerProvinceCode='', dealerCityCode='', dealerIds } = this.$route.query
      const params = {
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
        // dealerId: dealers[0]?.id,
        taskCode: this.taskData.codeConfig[this.taskCode],
        planTime: {
          start: this.$moment(this.start).format('YYYY-MM-DD 00:00:00'),
          end: this.$moment(this.end).format('YYYY-MM-DD 23:59:59'),
        },
        roleCode: roleCode,
        parentCode,
        childCode: childCode ? [childCode] : [],
        dealerProvinceCode,
        dealerCityCode,
        dealerIds: dealerIds ? [dealerIds] : []
      }
      if (type === 1) {
        params.dealerId = dealers[0]?.id
      }
      loading.showLoading()
      return todoServices.taskList(params).then((res) => {
        // 组装数据
        if (res.dataList.length) {
          res.dataList.forEach((item) => {
            if ((this.isForAppointment(item) || this.isForStorePdc(item)) && item.lead.appointments?.length) {
              // 预约试驾数据
              const appointment = item.lead.appointments[0]
              item.appointment = {
                ...appointment,
                customerName: item.lead.userName,
                type: appointment.typeCode,
                appointmentTime: `${dateFormat(
                  appointment.appointmentStartTime,
                  'YYYY-MM-DD HH:mm'
                )}-${dateFormat(appointment.appointmentEndTime, 'HH:mm')}`,
                followUserRoleCode: item.lead.followUserRoleCode,
                intentionSeriesName: appointment.seriesName || item.lead.seriesName,
                intentionModelName: appointment.modelName || item.lead.modelName,
                followRemark: appointment.followRemark || item.lead.followRemark
              }
            }
            if (this.isForOrder(item) && item.lead.orders?.length) {
              // 订单数据
              const order = item.lead.orders[0]
              item.order = {
                ...order,
              }
            }
          })
        }
        loading.hideLoading()
        this.totalCount = res.page.totalCount
        return res
      })
    },
    getPath(item, follow = false, followMode) {
      console.log('item:', item)

      const { taskCode, lead, relationId } = item
      switch (taskCode) {
        // 订单
        case 'ORDER_CONTRACT_PUSH':
        case 'ORDER_CONTRACT_SIGN':
        case 'ORDER_LOCK':
        case 'DELIVERY_VISIT':
        case 'ORDER_ASSIGN':
          this.$router.push({
            // path: follow ? '/order-follow' : '/order-detail',
            path: '/order-detail',
            query: {
              id: relationId,
              orderId: relationId,
              followMode,
              taskCode,
            },
          })
          return
        // 试驾
        case 'AFTER_DRIVE_FOLLOW"':
        case 'APPOINT_FOLLOW':
        case 'APPOINT_PDC':
        case 'APPOINT_TO_CONFIRM':
          this.$router.push({
            name: 'appointmentTestDriveDetail',
            query: {
              id: relationId,
              clueId: lead.id,
            },
          })
          return
        case 'APPOINT_STORE_PDC':
          this.$router.push({
            name:'appointmentDetail',
            query:{
              id: relationId,
              clueId: item.leadId
            }
          })
          return
        // 线索
        default:
          this.$router.push({
            path: follow ? '/clue-follow' : '/clue-details',
            query: {
              id: lead.id,
              followMode,
            },
          })
      }
    },
  },
}
</script>
<style lang="less" scoped>
.card {
  /deep/.bottom {
    height: auto;
    line-height: inherit;
    padding-top: 8px;
    align-items: center;
    margin-bottom: -4px;
  }
  .todo-actions {
    display: flex;
  }
}
.order {
  /deep/ .bottom {
    border: none;
    padding-top: 0;
    flex-wrap: wrap;
  }
  .todo-actions {
    margin-top: 8px;
    border-top: 1px solid rgba(13, 23, 26, 0.05);
    padding-top: 8px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
.assigned {
  align-self: stretch;
  display: flex;
  align-items: center;
  color: #b9921a;
  cursor: pointer;
}
/deep/ .van-dropdown-menu__bar {
  padding-left: 8px;
  padding-right: 16px;
  justify-content: space-between;
}
/deep/ .van-dropdown-item__content {
  max-width: 500px;
  left: 50%;
  transform: translateX(-50%);
}
/deep/ .van-dropdown-menu__item {
  flex: none;
}
.to-do-list {
  .yuqi {
    margin-right: auto;
    color: #e4002c;
		align-self: center;
  }
  .mb-8 {
    margin-bottom: 8px;
  }
  .total {
    height: 22px;
    font-size: 14px;
    font-family: HYQiHei, HYQiHei;
    font-weight: normal;
    color: #262626;
    line-height: 22px;
    margin: 9px 16px 0;
  }
  .item-wrap {
    position: relative;
    background: white;
    padding: 16px 12px 0 12px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    font-size: 13px;
    .user-info {
      padding-bottom: 8px;
      border-bottom: 1px solid rgba(13, 23, 26, 0.05);
    }
    .actions {
      border-top: 1px solid rgba(13, 23, 26, 0.05);
      height: 44px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;
      .ml-16 {
        margin-left: 16px;
      }
    }
  }
}
</style>
